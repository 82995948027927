<template>
  <FFHeader />
  <main>
    <div class="hero min-h-[calc(100vh-337px-64px)]">
      <div class="hero-content w-full flex-col text-gray-100 py-40">
        <h2 class="text-4xl font-bold mb-12">CONTACT</h2>

        <p class="text-center text-xl max-w-3xl mx-auto pb-8">Let's chat</p>

        <div class="w-full max-w-xl m-10 border-2 border-neutral rounded-2xl rotate-2 bg-gray-100 overflow-hidden">
          <div class="w-full p-8 border-4 border-dashed border-neutral">
            <form @submit.prevent="sendEmail" class="-rotate-2">
              <div class="mb-4">
                <label class="block text-gray-700">Name</label>
                <input type="text" v-model="form.name" id="name" class="w-full border border-gray-300 p-2 rounded-lg">
              </div>
              <div class="mb-4">
                <label class="block text-gray-700">Email</label>
                <input type="email" v-model="form.email" id="email" class="w-full border border-gray-300 p-2 rounded-lg">
              </div>
              <div class="mb-4">
                <label class="block text-gray-700">Message</label>
                <textarea
                    v-model="form.message"
                    id="message"
                    class="w-full border border-gray-300 p-2 rounded-lg h-40">
                </textarea>
              </div>
              <div class="flex justify-center">
                <button
                    type="submit"
                    :class="[
                    'btn',
                    'btn-primary',
                    'mb-8',
                    'bg-amber-400',
                    'border-0 shadow-lg shadow-indigo-500/50'
                  ]"
                >
                  Send Message
                </button>
              </div>

              <div
                  v-if="responseMessage"
                  role="alert"
                  class="mt-8 alert alert-success"
                  :class="{
                    'alert-success': isSuccess,
                    'alert-error': !isSuccess
                  }"
              >
                <svg
                    v-if="isSuccess"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 shrink-0 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24">
                  <path
                      class="text-gray-100"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 shrink-0 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24">
                  <path
                      class="text-gray-100"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="text-gray-100">{{ responseMessage }}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <MainFooter />
  </main>
  <FFFooter/>
</template>

<script>
import '../assets/tailwind.css';
import FFHeader from "@/components/FFHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import FFFooter from "@/components/FFFooter.vue";

export default {
  components: {
    FFFooter,
    MainFooter,
    FFHeader
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      responseMessage: '',
      isSuccess: false,
    };
  },
  methods: {
    async sendEmail() {
      this.isSuccess = false;
      this.responseMessage = '';

      try {
        const response = await fetch('/app.php/contact', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(this.form),
        });

        if (response.ok) {
          const result = await response.json();
          this.responseMessage = result.message || 'Email sent successfully!';
          this.isSuccess = true;
          this.form = {
            name: '',
            email: '',
            message: ''
          }
        } else {
          const error = await response.json();
          this.responseMessage = error.message || 'Failed to send email.';
        }
      } catch (err) {
        this.responseMessage = 'An error occurred. Please try again.';
      }
    },
  }
};
</script>