<template>
  <FFHeader />
  <main>
    <div class="hero h-screen max-h-[calc(100vh-337px-64px)]">
      <div class="hero-content text-gray-100">
        <h2 class="text-4xl font-bold mb-12">
          COMING SOON
        </h2>
      </div>
    </div>
    <MainFooter />
  </main>
  <FFFooter/>
</template>

<script>
import '../assets/tailwind.css';
import FFHeader from "@/components/FFHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import FFFooter from "@/components/FFFooter.vue";

export default {
  components: {
    FFFooter,
    MainFooter,
    FFHeader
  },
};
</script>