<template>
  <footer class="relative w-full bg-base-200">
    <svg-funkin-family width="128px" height="128px" classNames="absolute bottom-[64px] right-[128px] opacity-10" />

    <div class="footer m-auto w-full xl:w-3/4 text-base-content p-10">
      <nav>
        <h6 class="footer-title">Funkin Family Music</h6>
        <button v-on:click="gotTo('/about')" class="link link-hover">About</button>
        <button v-on:click="gotTo('/press-kit')" class="link link-hover">Press Kit</button>
        <button v-on:click="gotTo('/faq')" class="link link-hover">FAQ</button>
      </nav>
      <nav>
        <h6 class="footer-title">Funkin Family Games</h6>
        <button v-on:click="gotTo('/games')" class="link link-hover">Games</button>
        <button v-on:click="gotTo('/soundtracks')" class="link link-hover">Soundtracks</button>
      </nav>
      <nav>
        <h6 class="footer-title">Connect</h6>
        <button v-on:click="gotTo('/contact')" class="link link-hover">Contact</button>
        <a href="https://www.facebook.com/pillform" target="_blank" class="link link-hover">Facebook</a>
        <a href="https://x.com/funkinfamily" target="_blank" class="link link-hover">Twitter</a>
      </nav>
      <nav>
        <h6 class="footer-title">Legal</h6>
        <button v-on:click="gotTo('/privacy')" class="link link-hover">Privacy Policy</button>
        <button v-on:click="gotTo('/cookies')" class="link link-hover">Cookie Policy</button>
      </nav>
      <form @submit.prevent="signup" class="ml-auto">
        <h6 class="footer-title">Newsletter</h6>
        <fieldset class="form-control w-80">
          <label class="label">
            <span class="label-text">Enter your email address</span>
          </label>
          <div class="join">
            <input
              id="email"
              v-model="form.email"
              type="text"
              placeholder="username@site.com"
              class="input input-bordered join-item"
            />
            <button class="btn btn-primary bg-amber-400 join-item border-0 shadow-lg shadow-indigo-500/50">Subscribe</button>
          </div>
        </fieldset>
        <div
            v-if="responseMessage"
            class="mt-1"
            :class="{
              'text-green-500': isSuccess,
              'text-red-500': !isSuccess
            }"
        >
          {{responseMessage}}
        </div>
      </form>
    </div>
    <div class="footer m-auto w-full xl:w-3/4 text-base-content p-10">
      <nav>
        <h6 class="footer-title">Social</h6>
        <div class="flex">
          <a href="https://www.facebook.com/pillform" target="_blank" class="mr-4">
            <font-awesome-icon :icon="['fa-brands', 'facebook']" class="text-xl text-gray-400 hover:text-gray-100 transition-colors ease-out delay-100"/>
          </a>
          <a href="https://x.com/funkinfamily" target="_blank" class="mr-4">
            <font-awesome-icon :icon="['fa-brands', 'twitter']" class="text-xl text-gray-400 hover:text-gray-100 transition-colors ease-out delay-100"/>
          </a>
        </div>
      </nav>
    </div>
  </footer>
</template>

<script>
import SvgFunkinFamily from "@/components/SvgFunkinFamily.vue";

export default {
  name: 'MainFooter',
  components: {
    SvgFunkinFamily
  },
  data() {
    return {
      form: {
        email: '',
      },
      responseMessage: '',
      isSuccess: false,
    };
  },
  methods: {
    gotTo(route) {
      this.$router.push(route)
    },
    async signup() {
      this.isSuccess = false;
      this.responseMessage = '';

      try {
        const response = await fetch('/app.php/subscribe', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(this.form)
        });

        if (response.ok) {
          const result = await response.json();
          this.responseMessage = result.message;
          this.isSuccess = true;
          this.form.email = '';
        } else {
          const error = await response.json();
          this.responseMessage = error.message;
        }
      } catch (error) {
        this.responseMessage = 'An unexpected error occurred.';
      }
    }
  }
}
</script>
