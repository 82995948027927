<template>
  <FFHeader />
  <main class="min-h-max">
    <HeroAbout/>
    <MainFooter />
  </main>
  <FFFooter/>
</template>

<script>
import '../assets/tailwind.css';
import FFHeader from "@/components/FFHeader.vue";
import HeroAbout from "@/components/HeroAbout.vue";
import MainFooter from "@/components/MainFooter.vue";
import FFFooter from "@/components/FFFooter.vue";

export default {
  components: {
    FFFooter,
    MainFooter,
    HeroAbout,
    FFHeader
  },
};
</script>