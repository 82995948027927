<template>
  <div class="hero intro2 py-40 bg-gradient-to-tr from-gray-950 to-sky-950 overflow-hidden">
    <div class="hero-content text-center text-gray-100">
      <div class="max-w-xl">
        <h2 class="text-4xl font-bold mb-12">APPAREL</h2>

        <div class="relative border-2 border-dashed rounded-2xl rotate-2 bg-gradient-to-b from-gray-900 bg-purple-800 overflow-hidden">
          <div class="absolute top-1/2 -left-1/2 min-h-[100rem] min-w-[100rem] bg-purple-800 -rotate-3"></div>
          <div  class="p-8 -rotate-2">
            <img src="../assets/apparel.png" height="500" width="800" alt="Funkin Family Apparel" />

            <p class="py-6">
              Get some Funkin Family Apparel from MySpreadshirt.com. All proceeds help support
              this thing we do.
            </p>
            <a
                class="btn btn-primary border-0 shadow-lg shadow-indigo-500/50"
                href="https://funkinfamily.myspreadshop.com/"
                target="_blank"
            >
              MySpreadshop.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroApparel'
}
</script>

<style scoped>
.intro2 {
  position: relative;
  background: #4a0d61 radial-gradient(60% 100% at 40% -20%, #921dbe, #4a0d61) no-repeat;
}

.intro2:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url("../assets/PillFORM - NOW LIKE THIS.png");
  opacity: 0.2;
  background-size: cover;
}
</style>
