<template>
  <footer class="w-full bg-gray-950">
    <div class="text-neutral-content py-4 px-6 m-auto w-full xl:w-3/4">
      <aside class="flex justify-between items-center">
        <img class="h-8 min-w-8 md:ml-3" src="../assets/logo-w.png" alt="Funkin Family Music"/>
        <p>Copyright © 2024 - All right reserved</p>
      </aside>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FFFooter'
}
</script>
