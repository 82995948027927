<template>
  <FFHeader />
  <main class="min-h-max">
    <HeroNewRelease/>
    <!--hero-artists/ -->
    <HeroApparel/>
    <HeroAbout/>
    <MainFooter/>
  </main>
  <FFFooter/>
</template>

<script>
import '../assets/tailwind.css';
import FFHeader from "@/components/FFHeader.vue";
import HeroNewRelease from "@/components/HeroNewRelease.vue";
import HeroApparel from "@/components/HeroApparel.vue";
import HeroAbout from "@/components/HeroAbout.vue";
import MainFooter from "@/components/MainFooter.vue";
import FFFooter from "@/components/FFFooter.vue";
// import HeroArtists from "@/components/HeroArtists.vue";

export default {
  components: {
    // HeroArtists,
    FFFooter,
    MainFooter,
    HeroAbout,
    HeroApparel,
    HeroNewRelease,
    FFHeader
  },
};
</script>
