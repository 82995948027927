import { createRouter, createWebHistory} from 'vue-router'

import PageHome from "@/views/PageHome.vue";
import PageAbout from "@/views/PageAbout.vue";
import PageComingSoon from "@/views/PageComingSoon.vue";
import PageContact from "@/views/PageContact.vue";
import PageSoundtracks from "@/views/PageSoundtracks.vue";
import PageSoundtracksDae from "@/views/PageSoundtracksDae.vue";

const routes = [
    {
        path: '/',
        component: PageHome
    },
    {
        path: '/about',
        component: PageAbout
    },
    {
        path: '/contact',
        component: PageContact
    },
    {
        path: '/press-kit',
        component: PageComingSoon
    },
    {
        path: '/faq',
        component: PageComingSoon
    },
    {
        path: '/cookies',
        component: PageComingSoon
    },
    {
        path: '/privacy',
        component: PageComingSoon
    },
    {
        path: '/games',
        component: PageComingSoon
    },
    {
        path: '/soundtracks',
        component: PageSoundtracks
    },
    {
        path: '/soundtracks/dark-ambient-electronic',
        component: PageSoundtracksDae
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                top: -window.innerHeight
            }
        }
    },
})

export default router