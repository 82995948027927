<template>
  <FFHeader />
  <main>
    <div class="hero min-h-[calc(100vh-337px-64px)]">
      <div class="hero-content w-full flex-col text-gray-100 py-40">

        <h2 class="text-4xl font-bold mb-12">GAME SOUNDTRACKS</h2>

        <p class="text-center text-xl max-w-3xl mx-auto pb-4">Soundtracks for Unreal Engine games and development</p>

        <div class="w-full max-w-xl p-8">

          <div class="w-full max-w-2xl mb-8">
            <div class="join w-full border-2 border-dashed bg-base-200 rounded-full">
              <img class="join-item max-w-32 md:max-w-64" src="../assets/soundtracks/dark-ambient-electronic/images/dark-ambient-electronic.jpg" alt="Dark Ambient Electronic">
              <div class="join-item w-full flex">
                <button
                    class="btn btn-primary m-auto bg-opacity-0 hover:bg-opacity-0 text-gray-100 hover:text-amber-400 border-0"
                    v-on:click="gotTo('/soundtracks/dark-ambient-electronic')"
                >
                  Dark Ambient Electronic
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <MainFooter />
  </main>
  <FFFooter/>
</template>

<script>
import '../assets/tailwind.css';
import FFHeader from "@/components/FFHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import FFFooter from "@/components/FFFooter.vue";

export default {
  components: {
    FFFooter,
    MainFooter,
    FFHeader
  },
  methods: {
    gotTo(route) {
      this.$router.push(route)
    }
  }
};
</script>
