<template>
  <div class="hero alt py-40 overflow-hidden">
    <div class="hero-content text-center text-gray-100">
      <div class="max-w-xl">
        <svg-funkin-family classNames="m-auto" width="128px" height="128px"/>
        <h2 class="text-4xl font-bold">FUNKIN FAMILY MUSIC</h2>

        <p class="py-6">
          A haven for underground music lovers who crave the essential
          pulse of Breakbeat and the futuristic grooves of Electro Bass. Known for its funky, yet dark and deep sounds,
          the Family thrives on atmospheric vibes and psychedelic undertones that create a truly immersive experience.
          From the glimmering beaches to late-night warehouse parties, the air buzzes with the energy of heavy
          basslines and innovative music that pushes boundaries, blending tradition with a forward-thinking edge.
          Whether you're dancing under neon lights or drunk on a sunny beach, this sound is a journey
          into the unknown.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SvgFunkinFamily from "@/components/SvgFunkinFamily.vue"

export default {
  name: 'HeroAbout',
  components: {
    SvgFunkinFamily
  }
}
</script>

<style scoped>
.alt {
  position: relative;
  background: #805815 radial-gradient(60% 100% at 40% -20%, #dd9418, #805815) no-repeat;
}

.alt:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url("../assets/hero-tunnel.png");
  opacity: 0.2;
  background-size: cover;
}
</style>
