<template>
  <FFHeader />
  <main>
    <div class="hero min-h-[calc(100vh-337px-64px)]">
      <div class="hero-content w-full flex-col text-gray-100 py-20">
        <div class="w-full max-w-2xl text-left bg-base-200 bg-opacity-50 rounded-2xl p-4">
          <h1 class="text-4xl font-bold mt-8">GAME SOUNDTRACKS</h1>
          <h2 class="text-2xl font-bold mb-12">Dark, Ambient & Electronic</h2>

          <div class="w-full max-w-2xl mb-8">
            <div class="join w-full border-2 border-dashed -rotate-1">
              <img class="join-item max-w-32" src="../assets/soundtracks/dark-ambient-electronic/images/dark-ambient-electronic.jpg" alt="Dark Ambient Electronic">
              <div class="join-item w-full flex flex-col items-center justify-center">
                <h2 class="text-2xl font-bold mb-2">Available On</h2>
                <a
                    class="btn btn-primary bg-amber-400 border-0 shadow-lg shadow-indigo-500/50"
                    href="https://www.unrealengine.com/marketplace/en-US/product/aef67f87c8994a8492694d66965339f2"
                    target="_blank"
                >
                  Unreal Marketplace
                </a>
              </div>
            </div>
          </div>

          <img  class="rounded-xl" src="../assets/soundtracks/dark-ambient-electronic/images/dark-ambient-electronic.jpg" alt="Dark Ambient Electronic">

          <p class="my-8">
            A collection of 13 dark, ambient and electronic music inspired video game soundtrack motifs
            in various tempo and key signatures. Produced exclusively with hardware synths and samplers,
            containing no copyright materials and is 100% royalty free.
          </p>

          <p>
            All tracks are looping and include multi-track stems to be used
            however you like. Ample for creating new soundscapes or dynamic mixes in game with over
            6 hours of audio total.
          </p>

          <h2 class="text-2xl font-bold my-8">Preview On Soundcloud:</h2>
          <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1506369064&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/funkinfamily" title="funkinfamily" target="_blank" style="color: #cccccc; text-decoration: none;">funkinfamily</a> · <a href="https://soundcloud.com/funkinfamily/soundtracks-dark-ambient-and-electronic-preview" title="Soundtracks - Dark Ambient And Electronic Preview" target="_blank" style="color: #cccccc; text-decoration: none;">Soundtracks - Dark Ambient And Electronic Preview</a></div>

          <div class="text-left">
            <h2 class="text-2xl font-bold my-4">Tracks:</h2>
            <ul>
              <li>Track 1 -  B -	100bpm (2:52)</li>
              <li>Track 2 -  C -	120bpm (3:36)</li>
              <li>Track 3 -	D -	90bpm (2:50)</li>
              <li>Track 4 -	E -	60bpm (4:16)</li>
              <li>Track 5 -	E -	84bpm (3:28)</li>
              <li>Track 6 -	A -	60bpm (4:32)</li>
              <li>Track 7	-	E -	60bpm (6:24)</li>
              <li>Track 8 -	G -	60bpm (4:16)</li>
              <li>Track 9 -	G -	120bpm (4:16)</li>
              <li>Track 10 -	E -	128bpm (4:30)</li>
              <li>Track 11 -	E -	130bpm (3:26)</li>
              <li>Track 12 -	E -	160bpm (4:12)</li>
              <li>Track 13 -	E	- 130bpm (5:02)</li>
            </ul>

            <h2 class="text-2xl font-bold my-4">Multi-Tracks:</h2>
            <ul>
              <li>Track 1 [Parts 1-9] (2:52)</li>
              <li>Track 2 [Parts 1-11] (3:36)</li>
              <li>Track 3 [Parts 1-6] (2:50)</li>
              <li>Track 4 [Parts 1-4] (4:16)</li>
              <li>Track 5 [Parts 1-7] (3:28)</li>
              <li>Track 6 [Parts 1-5] (4:32)</li>
              <li>Track 7	[Parts 1-5] (6:24)</li>
              <li>Track 8 [Parts 1-8] (4:16)</li>
              <li>Track 9 [Parts 1-7] (4:16)</li>
              <li>Track 10 [Parts 1-6] (4:30)</li>
              <li>Track 11 [Parts 1-6] (3:26)</li>
              <li>Track 12 [Parts 1-6] (4:12)</li>
              <li>Track 13 [Parts 1-4] (5:02)</li>
            </ul>

            <h2 class="text-2xl font-bold my-4">More Info:</h2>
            <p class="mb-4">
              Number of Wave Files: 95 <br>
              Number of Cues: 26 <br>
              Sample rate / bit rate: 48,000 Hz / 24 Bit <br>
              Does music loop: Yes <br>
              Minutes of audio provided: 380 <br>
              Supported Development Platforms: <br>
              Windows: Yes
            </p>
          </div>
        </div>

        <div class="w-full max-w-2xl mb-8">
          <div class="join w-full border-2 border-dashed -rotate-1">
            <img
              class="join-item max-w-32"
              src="../assets/soundtracks/dark-ambient-electronic/images/dark-ambient-electronic.jpg"
              alt="Dark Ambient Electronic"
            />
            <div class="join-item w-full flex flex-col items-center justify-center">
              <h2 class="text-2xl font-bold mb-2">Available On</h2>
              <a
                  class="btn btn-primary bg-amber-400 border-0 shadow-lg shadow-indigo-500/50"
                  href="https://www.unrealengine.com/marketplace/en-US/product/aef67f87c8994a8492694d66965339f2"
                  target="_blank"
              >
                Unreal Marketplace
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter />
  </main>
  <FFFooter/>
</template>

<script>
import '../assets/tailwind.css';
import FFHeader from "@/components/FFHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import FFFooter from "@/components/FFFooter.vue";

export default {
  components: {
    FFFooter,
    MainFooter,
    FFHeader
  },
};
</script>
