<template>
  <svg-base>
    <template v-slot="slotProps">
      <svg
          :width="slotProps.width"
          :height="slotProps.height"
          :class="slotProps.classNames"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1036 1200"
      >
        <path
            d="M817.7 763.3c0 120-95.8 217-217 217-120 0-215.8-94.6-215.8-217 0-57.6 21.8-108.5 56.4-146.7L294.6 763.3l-77-77 165.5-163.7c22.4-22.4 33.9-51.5 33.9-83.6V233.6h108.5V439c0 63-22.4 116.4-66.1 160l-4.9 4.8c38.2-35.2 90.3-56.4 146.1-56.4 123.2.1 217.1 97.7 217.1 215.9m-98.2 0c0-64.3-53.3-116.4-118.8-116.4-66.1 0-117.6 54.6-117.6 116.4 0 63.6 52.1 117.6 117.6 117.6 67.9 0 118.8-55.1 118.8-117.6"
            style="fill:#fff"
        />
      </svg>
    </template>
  </svg-base>
</template>

<script>
import SvgBase from "@/components/SvgBase.vue";

export default {
  components: {
    SvgBase
  }
}
</script>
