<template>
  <slot :width="width" :height="height" :classNames="classNames" />
</template>

<script>
export default {
  components: {},
  props: {
    classNames: {
      type: String
    },
    width: {
      type: String,
      default: '30px'
    },
    height: {
      type: String,
      default: '30px'
    }
  }
}
</script>
