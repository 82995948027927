<template>
  <svg-base>
    <template v-slot="slotProps">
      <svg
          :width="slotProps.width"
          :height="slotProps.height"
          :class="slotProps.classNames"
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 843.26 1000"
      >
        <path
            d="M449.23 0H0v1000h268.21c24.97 0 45.22-20.24 45.22-45.22V762.69h67.15c328.35 0 462.68-185.05 462.68-401.49C843.26 94.05 640.27 0 449.23 0"
            style="fill:#fff"
        />
      </svg>
    </template>
  </svg-base>
</template>

<script>
import SvgBase from "@/components/SvgBase.vue";

export default {
  components: {
    SvgBase
  }
}
</script>