<template>
  <div class="hero intro min-h-screen overflow-hidden">
    <div class="hero-content flex-col text-gray-100 pt-40">
      <div class="max-w-xl text-center">

        <img class="h-auto max-w-64 m-auto mb-8" src="../assets/pillform-logo-p.png" alt="Funkin Family Music"/>

        <h1 class="text-5xl font-bold mb-8 rotate-[3deg]">OUT NOW</h1>

        <div class="join items-center border-2 border-dashed pr-8 rotate-[-2deg]">
          <img class="join-item h-auto max-w-32 mr-4 border-r-2 border-dashed"
               src="../assets/pillform=remembrain-thumb.png" alt=""/>
          <h2 class="join-item text-3xl font-bold -rotate-3">PillFORM - Remembrain EP</h2>
        </div>

        <p class="py-12">
          PillFORM is back! Florida’s underground electronic bass music legend returns,
          bringing new psychedelic vibes and raw breakbeat energy once again.
        </p>

        <h4 class="text-xl font-bold mb-2">
          Available On:
        </h4>

        <div class="flex gap-5 justify-center mb-12">
          <a href="https://pillform.bandcamp.com/album/remembrain-ep" target="_blank" title="Bandcamp">
            <font-awesome-icon :icon="['fa-brands', 'bandcamp']" class="text-3xl"/>
          </a>
          <a href="https://www.beatport.com/artist/pillform/34438" target="_blank" title="Beatport">
            <svg-beatport/>
          </a>
          <a href="https://music.apple.com/us/artist/pillform/343084853" target="_blank" title="iTunes / Apple Music">
            <font-awesome-icon :icon="['fa-brands', 'itunes']" class="text-3xl"/>
          </a>
        </div>

        <h4 class="text-xl font-bold mb-2">Streaming On:</h4>

        <div class="flex gap-5 justify-center">
          <a href="https://open.spotify.com/playlist/0pi1iz9xuOCNH9WSsIjVfi" target="_blank" title="Spotify">
            <font-awesome-icon :icon="['fa-brands', 'spotify']" class="text-3xl"/>
          </a>
          <a href="https://www.pandora.com/station/313205705712340876" target="_blank" title="Pandora">
            <svg-pandora/>
          </a>
          <a href="https://www.iheart.com/artist/pillform-798392/" target="_blank" title="iHeart Radio">
            <font-awesome-icon :icon="['fa-solid', 'fa-heart']" class="text-3xl"/>
          </a>
          <a href="https://www.deezer.com/us/artist/4024772" target="_blank" title="Deezer">
            <font-awesome-icon :icon="['fa-brands', 'fa-deezer']" class="text-3xl"/>
          </a>
        </div>

        <div class="flex gap-4 w-full mt-8 pb-16">
          <iframe
              class="rounded-2xl drop-shadow-2xl"
              src="https://open.spotify.com/embed/playlist/1vUPk25nyvnzHms1SdYxCl?utm_source=generator&theme=0"
              width="100%"
              height="370"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy">
          </iframe>
        </div>

        <h4 class="text-xl font-bold mb-2">Release Notes</h4>

        <blockquote class="p-4 my-4 border-s-4 border-orange-300 bg-pink-800 mb-12 rounded-2xl">
          <svg class="w-8 h-8 text-gray-400 dark:text-gray-600 mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
          </svg>
          <p class="mb-8 text-left">
            Membranes in biological systems demonstrate the principle of causality, where the selective permeability
            of the membrane causes specific molecular interactions that drive cellular processes, establishing a
            direct link between structure and function.
          </p>

          <p class="text-sm text-pink-300 text-left pb-6">
            - ChatGPT
          </p>
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>
import SvgBeatport from "@/components/SvgBeatport.vue"
import SvgPandora from "@/components/SvgPandora.vue"

export default {
  name: 'HeroNewRelease',
  components: {
    SvgBeatport,
    SvgPandora}
}
</script>

<style scoped>
.intro {
  position: relative;
  background: #c63d4b radial-gradient(60% 100% at 40% -20%, #e76898, #c63d4b) no-repeat;
}

.intro:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url("../assets/pillform - remembrain ep 4k.png");
  opacity: 0.2;
  background-size: cover;
}
</style>
