<template>
  <header class="w-full fixed top-0 z-50">
    <div class="navbar m-auto w-full lg:w-3/4 xl:w-2/3">
      <div class="flex-1">
        <a href="/">
          <span class="sr-only">Funkin Family Music</span>
          <img class="h-16 w-16 md:ml-3" src="../assets/logo.png" alt="Funkin Family Music"/>
        </a>
      </div>

      <div class="bg-gray-950 rounded-2xl rotate-1">
        <a
          href="https://www.beatport.com/artist/pillform/34438"
          class="md:hidden btn btn-link text-md no-underline hover:underline pl-2 pr-0"
        >
          <svg-beatport width="28px" height="28px"/>
        </a>
        <a
          href="https://music.apple.com/us/artist/pillform/343084853"
          class="md:hidden btn btn-link text-md no-underline hover:underline px-2"
        >
          <font-awesome-icon :icon="['fa-brands', 'itunes']" class="text-xl"/>
        </a>
        <a
          href="https://artists.spotify.com/c/artist/3UUYTQhbR0JfR36QKFAxSI/profile/"
          class="md:hidden btn btn-link text-md no-underline hover:underline px-2"
        >
          <font-awesome-icon :icon="['fa-brands', 'spotify']" class="text-xl"/>
        </a>
        <a
          href="https://www.pandora.com/station/313205705712340876"
          class="md:hidden btn btn-link text-md no-underline hover:underline px-2 mr-2"
        >
          <svg-pandora width="16px" height="16px"/>
        </a>

        <a
            :key="link.title"
            :href="link.url"
            target="_blank"
            v-for="link in streamLinks"
            class="hidden md:inline-flex btn btn-link text-md no-underline hover:underline mr-3"
        >
          <span class="hidden md:block">
            {{ link.title }}
          </span>
        </a>

        <div class="flex-none relative mr-4">
          <Menu>
            <MenuButton>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="inline-block h-6 w-6 stroke-current">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </MenuButton>
            <MenuItems class="absolute top-12 right-0 flex flex-col rounded-md bg-gray-950 -rotate-2">
              <MenuItem
                :key="page.title"
                v-for="page in pageLinks"
              >
                <button
                    v-on:click="gotTo(page.route)"
                    class="btn btn-link text-md no-underline hover:underline"
                >
                  {{ page.title }}
                </button>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import SvgBeatport from "@/components/SvgBeatport.vue";
import SvgPandora from "@/components/SvgPandora.vue";

export default {
  name: 'FFHeader',
  components: {
    SvgPandora,
    SvgBeatport,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },
  data: () => ({
    streamLinks: [
      {
        title: 'Beatport',
        url: 'https://www.beatport.com/artist/pillform/34438',
      },
      {
        title: 'iTunes',
        url: 'https://music.apple.com/us/artist/pillform/343084853',
      },
      {
        title: 'Spotify',
        url: 'https://artists.spotify.com/c/artist/3UUYTQhbR0JfR36QKFAxSI/profile/',
      },
      {
        title: 'Pandora',
        url: 'https://www.pandora.com/station/313205705712340876',
      }
    ],
    pageLinks: [
      {
        title: 'Home',
        route: '/',
      },
      {
        title: 'About',
        route: '/about',
      },
      {
        title: 'Contact',
        route: '/contact',
      }
    ]
  }),
  methods: {
    gotTo(route) {
      this.$router.push(route)
    }
  }
}
</script>
